import React, { useEffect, useRef, useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Form, FormItem, Input, Select, SubmitButton, Switch } from 'formik-antd';
import { useIntl } from 'react-intl';
import { Button, Col, Collapse, Divider, Row, Space, Tooltip, Tree, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { RiAddLine, RiArrowLeftLine } from 'react-icons/ri';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import unset from 'lodash/unset';
import cloneDeep from 'lodash/cloneDeep';
import { RxHamburgerMenu } from 'react-icons/rx';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import Yup from '../../../../vendor/yup';
import IntlMessages from '../../../../layout/components/lang/IntlMessages';
import urlPageTemplates from '../../../../urls/urlPageTemplates';
import {
  DOCS_EXTERNAL_LINK,
  TYPE_BOOLEAN,
  PLATFORM_OPTIONS, TYPE_OBJECT, DRAGGED_LIST_STYLE, DRAGGED_ITEM_STYLE, TYPE_ARRAY,
} from '../../const/templates';
import ActionButton from '../../../../layout/components/action-button';
import useToggle from '../../../../app/hooks/useToggle';
import Sidebar from '../../../../components/Sidebar';
import FormCreateEditTemplateField from './FormCreateEditTemplateField';
import makeSelectFilterOption from '../../../../lib/makeSelectFilterOption';
import TreeNode from '../../components/TreeNode';
import formatTemplateFieldValues from '../../utils/formatTemplateFieldValues';
import sortTreeDataByParentProperty from '../../utils/sortTreeDataByParentProperty';
import generateNodeHashKey from '../../utils/generateNodeHashKey';
import modifyDefaultValue from '../../utils/modifyDefaultValue';
import CountrySelector from '../../../../components/CountrySelector';
import SortableItem from '../../../../components/SortableItem';
import { countries } from '../../../../const/countries';
import { targetValidation } from '../../../../const/targetValidation';
import { useGetTemplateContentTypesQuery } from '../../api/templatesApiSlice';



const { Title } = Typography;
const { Panel } = Collapse;

const templateFieldInitial = {
  id: null,
  key: null,
  type: null,
  hint_en: null,
  hint_ru: null,
  title_en: null,
  title_ru: null,
  is_required: false,
  use_in_preset: false,
  subtype: null,
  validation: [],
  default_value: null,
  value: null,
};

const validationSchema = Yup.object().shape({
  app_key: Yup.string().matches(/^[A-Za-z0-9]+(?:[-_.][A-Za-z0-9]+)*$/, 'App key should be slug formatted.')
    .max(191, 'Max 191 symbol required')
    .required('Template app key is required'),
  title: Yup.string()
    .max(191, 'Max 191 symbol required')
    .required('Template title is required'),
  is_active: Yup.boolean().default(false),
  content_type_id: Yup.number().required('Content type is required'),
  target: targetValidation,
});

const FormCreateEditTemplate = ({
  initialValues,
  onSubmit = () => {},
  isSubmitting = false,
  formErrors = [],
  treeData,
  canEditTreeAndTarget = false,
  canEditStatus = false,
  isView = false,
  structures = {
    data: [],
  },
  isStructuresLoading = false,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [ isVisibleCreateSidebar, toggleCreateSidebar ]  = useToggle();
  const [ tree, setTree ] = useState({});
  const [ initialTree, setInitialTree ] = useState({});
  const [ visualTree, setVisualTree ] = useState([]);
  const [ sortItems, setSortItems ] = useState([]);
  const [ initialSortItems, setInitialSortItems ] = useState([]);
  const [ currentParentNode, setCurrentParentNode ] = useState(null);
  const [ formFieldKeys, setFormFieldKeys ] = useState([]);
  const [ deletedNodeKey, setDeletedNodeKey ] = useState(null);
  const [ currentField, setCurrentField ] = useState({});

  const currentHashKey = useRef(null);
  const isEdit = useRef(false);

  const {
    data: contentTypes = [],
    isFetchingContentTypes,
  } = useGetTemplateContentTypesQuery();

  const contentTypesOptions = contentTypes?.map((contentType) => {
    return {
      label: intl.formatMessage({ id: `structures-source-${contentType.name}` }),
      value: contentType.id,
    };
  });

  useEffect(() => {
    if (deletedNodeKey) {
      setSortItems(sortItems.filter((el) => el.hashKey !== deletedNodeKey));
      setDeletedNodeKey(null);
    }
  }, [ deletedNodeKey ]);

  const templateFieldInitialValues = !isEmpty(currentField)
    ? formatTemplateFieldValues(currentField, structures)
    : templateFieldInitial;

  const handleRemoveVisualTreeNode = (hashKey) => {
    const loopOverVisualTree = (nodes, hashKey) => {
      if (nodes.find((item) => item.key === hashKey)) {
        return nodes.filter((node) => {
          return node.key !== hashKey;
        });
      }

      nodes.forEach((node) => {
        if (node.children) {
          node.children = loopOverVisualTree(node.children, hashKey);
        }
      });

      return nodes;
    };

    setVisualTree((currentVisualTree) => {
      return loopOverVisualTree([ ...currentVisualTree ], hashKey);
    });
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!over || active?.id === over.id) return;

    const oldIndex = sortItems.findIndex((item) => item.hashKey === active.id);
    const newIndex = sortItems.findIndex((item) => item.hashKey === over.id);

    const updatedItems = arrayMove(sortItems, oldIndex, newIndex).map((item, index) => ({
      ...item,
      position: index,
    }));

    setSortItems(updatedItems);
  };

  const handleRemoveTreeNode = (hashKey) => {
    const loopOverTree = (nodes, hashKey) => {
      let neededNodeKey;

      // eslint-disable-next-line fp/no-loops,no-restricted-syntax
      for (const [ key, value ] of Object.entries(nodes)) {
        if (value?.hashKey === hashKey) {
          neededNodeKey = key;
        }
      }

      if (neededNodeKey) {
        unset(nodes, neededNodeKey);
      }

      Object.keys(nodes).forEach((nodeKey) => {
        if (nodes[nodeKey].items) {
          nodes[nodeKey].items = loopOverTree(nodes[nodeKey].items, hashKey);
        }
      });

      return nodes;
    };

    setTree((currentTree) => {
      return loopOverTree({ ...currentTree }, hashKey);
    });
  };


  const buildSortData = (treeData) => {
    const sortedItems = treeData
      .filter((el) => el.type !== TYPE_OBJECT)
      .sort((el, el2) => el.position > el2.position ? 1 : -1)
      .map((el, ind) => {
        if (el.position !== ind) {
          el.position = ind;
        }

        return el;
      });

    setSortItems(sortedItems);
    setInitialSortItems(cloneDeep(sortedItems));
  };

  const handleRemoveNode = (hashKey) => {
    setDeletedNodeKey(hashKey);
    handleRemoveVisualTreeNode(hashKey);
    handleRemoveTreeNode(hashKey);
  };

  const toggleIsEdit = (value) => {
    isEdit.current = value;
  };

  const handleCurrentNode = (node) => {
    setCurrentField(node);
    toggleIsEdit(true);
  };

  const handleCurrentParentNodeKey = (key) => {
    setCurrentParentNode(key);
  };

  const handleCurrentHashKey = (hashKey) => {
    currentHashKey.current = hashKey;
  };

  const addVisualTreeNodeComponent = (key, nodeData, hashKey, initialId = null) => {
    setFormFieldKeys((current) => [ ...current, hashKey ]);

    return {
      key: hashKey,
      id: key,
      initialId,
      className: 'treenode',
      title: (
        <TreeNode
          hashKey={hashKey}
          nodeKey={key}
          nodeData={nodeData}
          handleCurrentNodeKey={handleCurrentParentNodeKey}
          handleCurrentHashKey={handleCurrentHashKey}
          toggleSidebar={toggleCreateSidebar}
          toggleEdit={(isEdit) => toggleIsEdit(isEdit)}
          handleRemoveNode={handleRemoveNode}
          handleCurrentNode={handleCurrentNode}
          structures={structures}
          canEditTree={canEditTreeAndTarget}
        />
      ),
    };
  };

  const addVisualTreeNode  = (parentKey, newItemKey, newItem, hashKey) => {
    // is root level
    if (!parentKey) {
      setVisualTree([ ...visualTree, addVisualTreeNodeComponent(
        newItemKey,
        newItem,
        hashKey,
      ) ]);

      return;
    }

    const addNewItem = (nodes, parentKey, newItemKey, newItem, depth) => {
      if (nodes.find((item) => item.id === parentKey)) {
        return nodes.map((node) => {
          if (node.id === parentKey) {
            return {
              ...node,
              children: [
                ...node.children || [],
                addVisualTreeNodeComponent(
                  newItemKey,
                  newItem,
                  hashKey,
                ),
              ],
            };
          }

          return node;
        });
      }

      nodes.forEach((node) => {
        if (node.children) {
          node.children = addNewItem(node.children, parentKey, newItemKey, newItem, depth + 1);
        }
      });

      return nodes;
    };

    setVisualTree((currentVisualTree) => {
      return addNewItem([ ...currentVisualTree ], parentKey, newItemKey, newItem, 2);
    });
  };

  const addTreeNode = (parentKey, newItemKey, newItem, hashKey) => {
    if (!parentKey) {
      setTree({ ...tree, [newItemKey]: { ...newItem, hashKey } });

      return;
    }

    const addNewItem = (nodes, parentKey, newItem, hashKey) => {
      if (nodes[parentKey]) {
        return {
          ...nodes,
          [parentKey]: {
            ...nodes[parentKey],
            items: {
              ...(nodes[parentKey].items || {}),
              [newItemKey]: { ...newItem, hashKey },
            },
          },
        };
      }

      Object.keys(nodes).forEach((key) => {
        if (nodes[key].items) {
          nodes[key].items = addNewItem(nodes[key].items, parentKey, newItem, hashKey);
        }
      });

      return nodes;
    };

    setTree((currentTree) => {
      return addNewItem({ ...currentTree }, parentKey, newItem, hashKey);
    });
  };

  const addSortingNode = (key, newItem, hashKey) => {
    if (newItem.type === TYPE_OBJECT) {
      return;
    }
    newItem.name = key;
    newItem.hashKey = hashKey;
    newItem.position = (sortItems[sortItems.length - 1]?.position ?? 0) + 1;
    setSortItems([ ...sortItems, newItem ]);
  };


  const handleAddNode = ({ key, node }) => {
    const hashKey = generateNodeHashKey();

    addTreeNode(currentParentNode, key, node, hashKey);
    addVisualTreeNode(currentParentNode, key, node, hashKey);
    addSortingNode(key, node, hashKey);

    setCurrentParentNode(key);
  };

  const handleEditVisualTreeNode = (hashKey, key, node) => {
    const loopOverVisualTree = (nodes, hashKey, key, updatedNode) => {
      if (nodes.find((item) => item.key === hashKey)) {
        let oldNodeChildren;

        const updatedNodes = nodes.filter((node) => {
          if (node.key === hashKey) {
            oldNodeChildren = node.children ?? null;
          }

          return node.key !== hashKey;
        });

        const updatedVisualTreeNode = { ...addVisualTreeNodeComponent(
          key,
          updatedNode,
          hashKey,
        ) };

        if (oldNodeChildren) {
          updatedVisualTreeNode.children = oldNodeChildren;
        }

        return [
          ...updatedNodes,
          updatedVisualTreeNode,
        ];
      }

      nodes.forEach((node) => {
        if (node.children) {
          node.children = loopOverVisualTree(
            node.children,
            hashKey,
            key,
            updatedNode,
          );
        }
      });

      return nodes;
    };

    setVisualTree((currentVisualTree) => {
      return loopOverVisualTree(
        [ ...currentVisualTree ],
        hashKey,
        key,
        node,
      );
    });
  };

  const handleEditTreeNode = (hashKey, key, node) => {
    const loopOverTree = (nodes, hashKey, key, updatedNode) => {
      let neededNodeKey;

      // eslint-disable-next-line fp/no-loops,no-restricted-syntax
      for (const [ key, value ] of Object.entries(nodes)) {
        if (value?.hashKey === hashKey) {
          neededNodeKey = key;
        }
      }

      if (neededNodeKey) {
        const oldNodeItems = nodes[neededNodeKey].items ?? null;
        const oldNodeHashKey = nodes[neededNodeKey].hashKey;

        unset(nodes, neededNodeKey);

        const updatedTreeNode = { ...updatedNode, hashKey: oldNodeHashKey };

        if (oldNodeItems) {
          updatedTreeNode.items = oldNodeItems;
        }

        return {
          ...nodes,
          [key]: updatedTreeNode,
        };
      }

      Object.keys(nodes).forEach((nodeKey) => {
        if (nodes[nodeKey].items) {
          nodes[nodeKey].items = loopOverTree(
            nodes[nodeKey].items,
            hashKey,
            key,
            updatedNode,
          );
        }
      });

      return nodes;
    };

    setTree((currentTree) => {
      return loopOverTree(
        { ...currentTree },
        hashKey,
        key,
        node,
      );
    });
  };

  const handleEditNode = (hashKey, key, node) => {
    handleEditVisualTreeNode(hashKey, key, node);
    handleEditTreeNode(hashKey, key, node);
  };

  const handleCreateTemplateFieldSubmit = (values, isEdit, hashKey) => {
    if (isEdit && hashKey) {
      handleEditNode(hashKey, values.key, values.node);
    } else {
      handleAddNode(values);
    }

    toggleCreateSidebar();
    toggleIsEdit(false);
  };

  const titleWithNodes = (item) => {

    const fields = [];

    const titleRec = (item, arr) => {
      arr.push(item.name);
      if (!item.parent) {
        return;
      }
      titleRec(item.parent.data, arr);
    };

    titleRec(item, fields);
    return fields.reverse().join(' -> ');
  };

  const getDefaultValue = (node) => {
    switch (node.type) {
      case TYPE_BOOLEAN:
        return Boolean(Number(node.default_value));
      case TYPE_ARRAY:
        return node.default_value ?? [];
      default:
        return node.default_value;
    }
  };

  const getVisualNode = (node) => {
    return { ...addVisualTreeNodeComponent(node.name, {
      id: node.id,
      type: node.type,
      hint_en: node.hint_en,
      hint_ru: node.hint_ru,
      title_en: node.title_en,
      title_ru: node.title_ru,
      use_in_preset: node.use_in_preset,
      validation: node?.validations.data.map((validationRule) => validationRule.rule),
      default_value: getDefaultValue(node),
      subtype: node.subtype,
      value: node.value,
    }, node.hashKey, node.id),
    children: [],
    };
  };

  const getNode = (data) => {
    const node = {
      id: data.id,
      type: data.type,
      hint_en: data.hint_en,
      hint_ru: data.hint_ru,
      title_en: data.title_en,
      title_ru: data.title_ru,
      use_in_preset: data.use_in_preset,
      validation: data?.validations.data
        .map((validationRule) => validationRule.rule),
      items: {},
      initialId: data.id,
      hashKey: data.hashKey,
    };

    if (data.subtype) {
      node.subtype = data.subtype;
    }

    if (data.value) {
      node.value = data.value;
    }

    node.default_value = modifyDefaultValue(data);

    return node;
  };

  const buildVisualTreeFromInitial = (treeData) => {
    if (isEmpty(treeData)) {
      return [];
    }

    let visualTree = [];

    // eslint-disable-next-line fp/no-loops,no-restricted-syntax
    for (const node of treeData) {
      if (!node.hasOwnProperty('parent')) {
        visualTree.push(getVisualNode(node));
      } else {
        const loopOverTree = (nodes, node, depth) => {
          if (nodes.find((currentNode) => currentNode?.initialId === node.parent.data.id)) {
            return nodes.map((currentNode) => {
              if (currentNode?.initialId === node.parent.data.id) {
                currentNode.children.push(getVisualNode(node));
              }
              return currentNode;
            });
          }

          // eslint-disable-next-line fp/no-loops,no-restricted-syntax
          for (const visualNode of nodes) {
            if (visualNode.children) {
              visualNode.children = loopOverTree(visualNode.children, node, depth + 1);
            }
          }
          return nodes;
        };

        visualTree = loopOverTree(visualTree, node, 1);
      }
    }

    buildSortData(treeData);

    return visualTree;
  };

  const buildTreeFromInitial = (treeData) => {
    if (isEmpty(treeData)) {
      return {};
    }

    let tree = {};

    // eslint-disable-next-line fp/no-loops,no-restricted-syntax
    for (const node of treeData) {
      if (!node.hasOwnProperty('parent')) {
        tree[node.name] = getNode(node);
      } else {
        const loopOverTree = (nodes, node) => {
          // eslint-disable-next-line fp/no-loops,no-restricted-syntax
          for (const currentNode of Object.values(nodes)) {
            if (currentNode.initialId === node.parent.data.id) {
              currentNode.items = {
                ...currentNode.items,
                [node.name]: getNode(node),
              };
              return nodes;
            }
          }

          Object.keys(nodes).forEach((key) => {
            if (nodes[key].items) {
              nodes[key].items = loopOverTree(nodes[key].items, node);
            }
          });
          return nodes;
        };

        tree = loopOverTree(tree, node);
      }
    }
    return tree;
  };

  useEffect(() => {
    const copyOfTreeData = [ ...treeData.map((treeNodeData) => {
      return { ...treeNodeData, hashKey: generateNodeHashKey() };
    }) ];

    copyOfTreeData.sort(sortTreeDataByParentProperty);
    setVisualTree(!isEmpty(visualTree) ? visualTree : buildVisualTreeFromInitial(copyOfTreeData));

    const initialTreeData = !isEmpty(tree) ? tree : buildTreeFromInitial(copyOfTreeData);

    setTree(initialTreeData);
    setInitialTree(cloneDeep(initialTreeData));
  }, [ treeData, structures ]);

  const handleSubmit = (values) => {
    const { is_main, ...formValues } = values;

    if (!canEditStatus) {
      unset(formValues, 'is_active');
    }

    if (canEditTreeAndTarget) {
      formValues.root = tree;
    }

    formValues.sort = sortItems.map((el) => {
      return { id: el.id, name: el.name, position: el.position, is_main: el?.is_main ?? false, hashKey: el.hashKey };
    });

    onSubmit(formValues);
  };

  const handleOnPointerUp = async (event, setFieldValue, index) => {
    const switchBtn = event.target.closest('.ant-switch');

    if (switchBtn) {
      const value = switchBtn.getAttribute('aria-checked') === 'true';

      await setFieldValue(`is_main[${index}]`, !value, true);

      setSortItems((prevState) => {
        return prevState.map((item, sortableItemIndex) => index === sortableItemIndex ? { ...item, is_main: !value } : item);
      });

      if (switchBtn.classList.contains('ant-switch-checked')) {
        switchBtn.classList.remove('ant-switch-checked');
      } else {
        switchBtn.classList.add('ant-switch-checked');
      }
    }
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      initialErrors={formErrors}
    >
      {({ values, isValid, dirty, setFieldValue }) => {
        return (
          <Form layout="vertical" className='w-full'>
            {!isView && (
              <Row className="hp-print-none hp-mt-8 hp-mb-16">
                <Col span={24}>
                  <Link to={DOCS_EXTERNAL_LINK} target="_blank">
                    <IntlMessages id="templates-create-form-docs" />
                    <QuestionCircleOutlined className="hp-ml-8" />
                  </Link>
                </Col>
              </Row>
            )}

            <FormItem
              className='ant-form-item-col stretched'
              name="app_key"
              label={
                <Row className='w-full' justify="space-between" align="middle">
                  <IntlMessages id="templates-create-form-app-key-label" />
                  <Tooltip placement="topRight" title={<IntlMessages id="templates-create-form-app-key-help" />}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </Row>
              }
              required
            >
              <Input
                disabled={isView}
                name='app_key'
                placeholder={intl.formatMessage({ id: 'templates-create-form-app-key-placeholder' })}
              />
            </FormItem>

            <FormItem
              className='ant-form-item-col'
              name="title"
              label={<IntlMessages id="templates-create-form-title-label" />}
              required
            >
              <Input
                disabled={isView}
                name='title'
                placeholder={intl.formatMessage({ id: 'templates-create-form-title-placeholder' })}
              />
            </FormItem>

            <FormItem
              className='ant-form-item-col'
              name='content_type_id'
              label={<IntlMessages id="templates-create-form-content-type-label" />}
              required
            >
              <Select
                allowClear
                showSearch
                filterOption={(input, option) => makeSelectFilterOption(option.label, input)}
                disabled={isView || !canEditTreeAndTarget}
                loading={isFetchingContentTypes}
                options={contentTypesOptions}
                name='content_type_id'
                placeholder={intl.formatMessage({ id: 'templates-create-form-content-type-placeholder' })}
              />
            </FormItem>

            <FormItem
              className='ant-form-item-col is-active-status'
              label={intl.formatMessage({ id: 'ui-general-status' })}
              name='is_active'
            >
              <Switch
                name="is_active"
                disabled={!canEditStatus}
              />
            </FormItem>

            <Divider />

            {canEditTreeAndTarget && (
              <Row className="hp-print-none hp-mb-16">
                <Col span={24}>
                  <span className='template-required-field'>
                    <IntlMessages id='templates-create-form-add-structure-root' />
                  </span>
                  <ActionButton
                    className="hp-ml-16"
                    title={<IntlMessages id="ui-general-create" />}
                    icon={<RiAddLine className="btn-icon-mr-1" />}
                    onClick={() => {
                      setCurrentParentNode(null);
                      currentHashKey.current = null;
                      setCurrentField({});
                      toggleIsEdit(false);
                      toggleCreateSidebar();
                    }}
                  />
                </Col>
              </Row>
            )}

            {!isEmpty(visualTree) && (
              <Row className="hp-print-none hp-mb-16">
                <Col span={24}>
                  <Tree
                    defaultExpandAll
                    selectable={false}
                    treeData={visualTree}
                    expandedKeys={formFieldKeys}
                    showLine
                    className="hide-file-icon"
                  />
                </Col>
              </Row>
            )}

            <Collapse expandIconPosition="right" className="hp-mb-12">
              <Panel
                className='hp-p-0 template-priority'
                header={
                  <>
                    <Title level={5} className='collapse-item-title'><IntlMessages id="dynamic-news-fields-priority" /></Title>
                    <Tooltip className='hp-ml-8' placement="topRight" title={<IntlMessages id="templates-create-form-priority-help" />}>
                      <InfoCircleOutlined />
                    </Tooltip>
                  </>
                }
                key="1"
              >
                <div style={{ margin: '0px -24px' }}>
                  <div className="hp-text-right small hp-badge-text hp-px-16 hp-pb-16 hp-border-bottom-1">
                    <IntlMessages id="dynamic-news-form-main-attribute" />
                  </div>
                  <DndContext
                    modifiers={[ restrictToParentElement ]}
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                  >
                    <SortableContext
                      items={sortItems.map((item) => item.hashKey)}
                      strategy={verticalListSortingStrategy}
                    >
                      <div style={DRAGGED_LIST_STYLE} className="grag">
                        {sortItems.map((item, index) => (
                          <SortableItem
                            key={item.hashKey}
                            id={item.hashKey}
                            disabled={isView}
                          >
                            <div
                              style={{
                                ...DRAGGED_ITEM_STYLE(false),
                                cursor: isView ? 'not-allowed' : 'grab',
                              }}
                              onPointerUp={(event) => handleOnPointerUp(event, setFieldValue, index)}
                            >
                              <div className='hp-d-flex hp-d-flex-justify-between hp-vertical-align-bottom hp-flex-wrap'>
                                <div>
                                  <Space size={16}>
                                    <RxHamburgerMenu style={{ position: 'relative', top: '2px' }} />
                                    <Title className='hp-mb-0 text-trim' level={5}>{item?.title_en ?? ''}</Title>
                                    <span className='hp-text-color-black-60 text-sm text-trim'>{titleWithNodes(item)}</span>
                                  </Space>
                                </div>
                                <div>
                                  <Switch
                                    disabled={isView}
                                    name={`is_main[${index}]`}
                                    checked={item.is_main}
                                  />
                                </div>
                              </div>
                            </div>
                          </SortableItem>
                        ))}
                      </div>
                    </SortableContext>
                  </DndContext>
                </div>
              </Panel>
            </Collapse>

            <Divider />

            <Collapse expandIconPosition="right" className="hp-mb-12">
              <Panel className='hp-p-0 test' header={<Title level={5} className='collapse-item-title'><IntlMessages id="templates-create-form-add-target" /></Title>} key="1">

                <Row gutter={16}>
                  <Col span={12}>
                    <FormItem
                      label={intl.formatMessage({ id: 'ui-general-min-version' })}
                      name='target.min_version'
                    >
                      <Input
                        disabled={!canEditTreeAndTarget}
                        name="target.min_version"
                        placeholder={intl.formatMessage({ id: 'templates-create-form-targets-min-version-placeholder' })}
                      />
                    </FormItem>
                  </Col>

                  <Col span={12}>
                    <FormItem
                      label={intl.formatMessage({ id: 'ui-general-max-version' })}
                      name='target.max_version'
                    >
                      <Input
                        disabled={!canEditTreeAndTarget}
                        name="target.max_version"
                        placeholder={intl.formatMessage({ id: 'templates-create-form-targets-max-version-placeholder' })}
                      />
                    </FormItem>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={24}>
                    <FormItem
                      label={intl.formatMessage({ id: 'ui-general-platform' })}
                      name='target.platform'
                      required
                    >
                      <Select
                        allowClear
                        mode="multiple"
                        disabled={!canEditTreeAndTarget}
                        name="target.platform"
                        options={PLATFORM_OPTIONS}
                        placeholder={intl.formatMessage({ id: 'ui-general-choose-platform' })}
                      />
                    </FormItem>
                  </Col>

                  <Col span={24}>
                    <FormItem
                      className='ant-form-item-col stretched'
                      label={(
                        <Row className='w-full' justify="space-between" align="middle">
                          <IntlMessages id="ui-general-countries" />
                          <Tooltip
                            placement="topRight"
                            title={<IntlMessages id="templates-create-form-targets-countries-label-help" />}
                          >
                            <InfoCircleOutlined />
                          </Tooltip>
                        </Row>
                      )}
                      name='target.countries'
                      required
                    >
                      <CountrySelector
                        onSelectAll={async () => {
                          await setFieldValue(
                            'target.countries',
                            countries.map((country) => country.value).sort(),
                          );
                        }}
                        disabled={!canEditTreeAndTarget}
                        onSelectAllDisabled={values?.target?.countries?.length === countries.length}
                        name='target.countries'
                        placeholder={intl.formatMessage({ id: 'ui-general-choose-countries' })}
                        isView={isView}
                        setFieldValue={setFieldValue}
                      />
                    </FormItem>
                  </Col>
                </Row>

              </Panel>
            </Collapse>

            <Row gutter={[ 16, 16 ]} justify='end'>
              <Col>
                <Button
                  icon={<RiArrowLeftLine className="hp-mr-8" size={18} />}
                  onClick={() => navigate(urlPageTemplates())}
                >
                  <IntlMessages id='ui-general-back-to-list' />
                </Button>
              </Col>
              {!isView && (
                <Col>
                  <SubmitButton
                    loading={isSubmitting}
                    disabled={
                      !isValid
                      || (!dirty && isEqual(tree, initialTree) && isEqual(sortItems, initialSortItems))
                      || isEmpty(tree)
                      || isEmpty(sortItems)
                    }
                  >
                    {!isEmpty(treeData) ? <IntlMessages id='ui-general-update' /> : <IntlMessages id='ui-general-save' />}
                  </SubmitButton>
                </Col>
              )}
            </Row>

            <Sidebar
              title={<Title level={5}>{intl.formatMessage({ id: 'templates-create-form-add-structure-node' })}</Title>}
              visible={isVisibleCreateSidebar}
              toggleSidebar={toggleCreateSidebar}
            >
              <FormCreateEditTemplateField
                isEdit={isEdit.current}
                initialValues={templateFieldInitialValues}
                onSubmit={handleCreateTemplateFieldSubmit}
                currentHashKey={currentHashKey.current}
                tree={tree}
                structures={structures}
                isStructuresLoading={isStructuresLoading}
              />
            </Sidebar>
          </Form>
        );
      }}
    </Formik>
  );
};

FormCreateEditTemplate.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object.isRequired,
  formErrors: PropTypes.array,
  isSubmitting: PropTypes.bool,
  treeData: PropTypes.array,
  canEditTreeAndTarget: PropTypes.bool,
  canEditStatus: PropTypes.bool,
  isView: PropTypes.bool,
  structures: PropTypes.object,
  isStructuresLoading: PropTypes.bool,
};

export default FormCreateEditTemplate;
