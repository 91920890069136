import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import isObject from 'lodash/isObject';
import { Button, Card, Col, Modal, Pagination, Popconfirm, Row, Space, Tooltip } from 'antd';
import { RiArrowLeftLine, RiCloseFill, RiErrorWarningLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { MdOutlineUnpublished } from 'react-icons/md';
import dayjs from 'dayjs';
import { Filter } from 'iconsax-react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import Yup from '../../../../../vendor/yup';
import appColors from '../../../../../const/colors';
import IntlMessages from '../../../../../layout/components/lang/IntlMessages';
import urlPageCollections from '../../../../../urls/urlPageCollections';
import urlPageCollectionDetails from '../../../../../urls/urlPageCollectionDetails';
import { TYPE_IMAGE, TYPE_OBJECT } from '../../../../Template builder/const/templates';
import { fieldTypeToValidationRule } from '../../../forms/FormCreateDynamicNews/FormCreateDynamicNews.const';
import { searchFilterFields } from '../../../forms/FormImagesFilter/FormImagesFilter.const';
import { imageStatuses } from '../../../../../const/imageStatuses';
import { COLLECTION_TEMPLATE_CONTENT_TYPE, COLLECTIONS_SOURCE } from '../PageCollections/PageCollections.const';
import { makeValidationSchema } from '../../../forms/FormEditCollection/FormEditCollection.const';
import { prepareImageInitValues } from './PageEditCollection.const';
import handleResponse from '../../../../../lib/handleResponse';
import transformErrors from '../../../../../lib/transformErrors';
import useModifiedDynamicDataErrors from '../../../../../app/hooks/useModifiedDynamicDataErrors';
import isFieldRequired from '../../../utils/isFieldRequired';
import createFileFromURL from '../../../../../lib/createFileFromURL';
import getConfigurations from '../../../../../lib/getConfigurations';
import useCategoriesOptions from '../../../../../app/hooks/useCategoriesOptions';
import useImageTypeOptions from '../../../../../app/hooks/useImageTypeOptions';
import useQueryParams from '../../../../../app/hooks/useQueryParams';
import useToggle from '../../../../../app/hooks/useToggle';
import useScrollToImagesOnSearch from '../../../hooks/useScrollToImagesOnSearch';
import resetFilter from '../../../../../lib/resetFilter';
import applyFilter from '../../../../../lib/applyFilter';
import ActionButton from '../../../../../layout/components/action-button';
import BreadCrumbs from '../../../../../layout/components/breadcrumbs';
import Sidebar from '../../../../../components/Sidebar';
import FormImagesFilter from '../../../forms/FormImagesFilter';
import FormEditCollection from '../../../forms/FormEditCollection';
import ContentCard from '../../../components/ContentCard';
import EmptyDataPlaceholder from '../../../../../components/EmptyDataPlaceholder';
import TotalLabel from '../../../../../components/TotalLabel';
import Spinner from '../../../../../components/Spinner';
import ImageModalCardBodyView from '../../../components/ImageModalCard/ImageModalCardBodyView';
import EditCollectionImageCard from '../../../components/EditCollectionImageCard';
import {
  useGetSpecificCollectionQuery,
  useUnpublishCollectionMutation,
  useUpdateCollectionMutation,
} from '../../../api/collectionsApiSlice';
import { useGetCategoriesQuery } from '../../../api/categoriesApiSlice';
import { useAttachImageMutation, useGetImagesQuery } from '../../../api/imagesApiSlice';



let validation = {};

const PageEditCollection = () => {
  const { collectionId } = useParams();
  const intl = useIntl();
  const navigate = useNavigate();
  const [ searchQueryParams, setSearchParams ] = useSearchParams();
  const [ errors, setFormErrors ] = useState({});
  const [ needToAttachImageWithUpdate, setNeedToAttachImageWithUpdate ] = useState(false);
  const [ isEdit, setIsEdit ] = useState(false);
  const [ isSyntheticImagesLoading, setImagesLoading ] = useState(false);
  const [ isFilterOpen, toggleFilterSidebar ] = useToggle();
  const [ previewModalOpen, togglePreviewModal ] = useToggle();
  const [ previewImage, setPreviewImage ] = useState({});
  const [ initialValues, setInitialValues ] = useState({ preset_id: null, data: {}, target: {} });
  const language = useSelector(({ customise: { language } }) => language);
  const [ imageInitialValues, setImageInitialValues ] = useState({});

  const collectionConfig = getConfigurations(COLLECTIONS_SOURCE);
  const imageTypes = collectionConfig?.image_types ?? [];

  const {
    pagination,
    searchParams,
    initFilterValues,
    setInitFilterValues,
    handleChangeTableParams,
  } = useQueryParams({
    searchFilterFields,
    paginationInit: { page: 1, limit: 12 } });

  const [ updateCollection, { isLoading: isUpdatingCollection } ] = useUpdateCollectionMutation();
  const [ attachImage, { isLoading: isAttaching } ] = useAttachImageMutation();
  const [ unpublishCollection, { isLoading: isUnpublishCollectionLoading } ] = useUnpublishCollectionMutation();
  const { data: collectionDetails = {}, isFetching: isFetchingCollection } = useGetSpecificCollectionQuery(collectionId, { skip: !collectionId });
  const { data: categories = { data: [] }, isLoading: isCategoriesLoading } = useGetCategoriesQuery({
    queryParams: 'limit=0',
  });

  const { data: {
    data: images = [],
    pagination: dataPagination = {},
  } = { data: [], pagination: {} }, isFetching: isImagesFetching } = useGetImagesQuery({
    queryParams: searchParams.toString().includes('search') ?
      `${searchParams.toString()};status:${collectionDetails.image_type};collection_id:${collectionDetails.id}` :
      `${searchParams.toString()}&search=status:${collectionDetails.image_type};collection_id:${collectionDetails.id}`,
  }, { skip: !collectionDetails?.image_type });

  const attachedImagesCount = collectionDetails?.images?.data?.length;
  const imagesRef = useScrollToImagesOnSearch(images, searchQueryParams);

  const categoriesOptions = useCategoriesOptions(categories?.data);
  const imageTypeOptions = useImageTypeOptions(imageTypes);
  const allowUnpublishPast = collectionConfig?.publish?.allow_unpublish_past ?? true;
  const allowUnpublish = allowUnpublishPast ?
    Boolean(collectionDetails?.is_published) :
    Boolean(collectionDetails?.is_published) && dayjs().isSameOrBefore(dayjs(collectionDetails?.released_at), 'day');

  const modifiedErrors = useModifiedDynamicDataErrors(
    collectionDetails?.template?.data.id,
    collectionDetails?.template?.data,
    errors,
    language,
  );


  useEffect(() => {
    setImagesLoading(isEmpty(collectionDetails?.image_type));
  }, [ collectionDetails?.image_type ]);

  const getDynamicData = (collectionData) => {
    const data = {};
    const dataSchema = {};

    collectionData?.template?.data?.fields?.data.filter((field) => field.type !== TYPE_OBJECT)
      .forEach(async (field) => {
        const collectionField = collectionData?.dynamic_data?.data?.find((item) => item.template_field_id === field.id);

        if (isFieldRequired(field?.validations?.data || [])) {
          dataSchema[field.full_name] = Yup.object().shape({
            template_field_id: Yup.number().required(),
            ...fieldTypeToValidationRule[field.type],
          });
        }

        if (field.type === TYPE_IMAGE) {
          if (collectionField?.value) {
            const fileName = collectionField.value?.split('/').pop();
            const file = await createFileFromURL(collectionField.value, fileName);

            data[field.full_name] = {
              value: [ {
                uid: '-1',
                name: collectionField?.value,
                thumbUrl: collectionField?.value,
                status: 'done',
                originFileObj: file,
                url: collectionField?.value,
              } ],
              template_field_id: field.id,
            };
          } else {
            data[field.full_name] = {
              value: [],
              template_field_id: field.id,
            };
          }
        } else {
          data[field.full_name] = {
            value: collectionField?.value,
            template_field_id: field.id,
          };
        }

        setInitialValues((prevState) => ({ ...prevState, data }));
      });

    validation = makeValidationSchema(
      collectionConfig,
      collectionDetails,
      { data: Yup.object().shape(dataSchema) },
    );

    return data;
  };

  useEffect(() => {
    setInitialValues({
      update_image_release_dates: false,
      released_at: collectionDetails?.released_at,
      released_to: collectionDetails?.released_to,
      use_utc_zero_time: collectionDetails?.use_utc_zero_time,
      template_id: collectionDetails?.template?.data?.id,
      preset_id: null,
      title: collectionDetails?.title,
      categories: collectionDetails?.categories?.data?.map((category) => category.id),
      image_type: collectionDetails?.image_type,
      is_published: false,
      updated_at: collectionDetails?.updated_at,
      published_at: collectionDetails?.published_at,
      target: {
        min_version: collectionDetails?.target?.data?.min_version ?? '',
        max_version: collectionDetails?.target?.data?.max_version ?? '',
        platform: collectionDetails?.target?.data?.platform,
        countries: [ ...collectionDetails?.target?.data?.countries ?? [] ].sort(),
      },
      data: {},
    });

    getDynamicData(collectionDetails);

    setImageInitialValues(prepareImageInitValues(collectionDetails));
  }, [ isFetchingCollection ]);

  const handleUpdateCollection = (values) => {
    updateCollection({ collectionId, values })
      .unwrap()
      .then((response) => handleResponse(
        'success',
        intl,
        'ui-general-updated-successfully',
        [ () => {
          if (moment(response?.data?.updated_at).isSame(moment(response?.data?.published_at))) {
            navigate(urlPageCollectionDetails({ collectionId }));
          }
        } ],
      ))
      .catch((error) => {
        handleResponse(
          'error',
          intl,
          'ui-general-update-failed',
          [ () => {
            if (isObject(error?.data?.errors)) {
              setFormErrors(transformErrors(error?.data?.errors));
            }
          } ],
          error,
        );
      });
  };

  const handleUnpublish = () => {
    unpublishCollection(collectionId)
      .unwrap()
      .then(() => handleResponse('success', intl, 'ui-general-unpublished-successfully'))
      .catch((error) => handleResponse('error', intl, 'ui-general-unpublish-failed', [], error));
  };

  const handleAttachImage = (imageId, status, isClose = null) => {
    const data = {
      attached_to: collectionId,
      type: COLLECTION_TEMPLATE_CONTENT_TYPE,
      status,
      isClose,
    };

    attachImage({ id: imageId, payload: data })
      .unwrap()
      .then(() => handleResponse('success', intl, 'collection-attach-success'))
      .catch((error) => handleResponse('error', intl, 'collection-attach-failed', [], error));
  };

  const handleCardClick = (item) => {
    setPreviewImage(item);
    togglePreviewModal();
  };

  const actions = (item, isDetach = false) => {
    return [
      (!isDetach ? item.status === imageStatuses.ready_for_release ? (
        <Button
          key="download"
          className='w-full hp-p-0 attach-modal-btn'
          onClick={() => {
            setNeedToAttachImageWithUpdate(true);
            setIsEdit(true);
            handleCardClick(item);
          }}
        >
          <PlusOutlined style={{ color: appColors.mediumGreen }} />
        </Button>
      ) : (
        <Popconfirm
          title={<IntlMessages id="collection-attach-confirm-message" />}
          placement="top"
          onConfirm={() => {
            handleAttachImage(item.id, imageStatuses.release_on);
          }}
          okText={<IntlMessages id="ui-general-yes" />}
          cancelText={<IntlMessages id="ui-general-no" />}
          icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
        >
          <PlusOutlined style={{ color: appColors.mediumGreen }} />
        </Popconfirm>
      ) : (
        <Tooltip
          key="download"
          placement="bottom"
          title={<IntlMessages id='ui-general-detach-image' />}
        >
          <Popconfirm
            title={<IntlMessages id="collection-detach-confirm-message" />}
            placement="top"
            onConfirm={() => {
              if (item.status === imageStatuses.release_on) {
                handleAttachImage(item.id, imageStatuses.release_on, true);
              } else {
                handleAttachImage(item.id, imageStatuses.ready_for_release);
              }
            }}
            okText={<IntlMessages id="ui-general-yes" />}
            cancelText={<IntlMessages id="ui-general-no" />}
            icon={<RiErrorWarningLine className="remix-icon hp-text-color-primary-1" />}
            okButtonProps={{ danger: true }}
          >
            <DeleteOutlined style={{ color: appColors.orange }} />
          </Popconfirm>
        </Tooltip>)
      ),
    ];
  };

  const handlePreviewModalClose = () => {
    setImageInitialValues(prepareImageInitValues(collectionDetails));
    togglePreviewModal();
  };

  const handleClickOnAttachedImage = (item) => {
    setImageInitialValues({
      unique_id: Date.now(),
      released_at: item?.published_at,
      released_to: item?.published_to,
      use_utc_zero_time: item?.use_utc_zero_time ?? false,
    });
    setIsEdit(item.status === imageStatuses.pack);
    setNeedToAttachImageWithUpdate(false);
    handleCardClick(item);
  };

  const handleClickOnNonAttachedImage = (item) => {
    setIsEdit(false);
    handleCardClick(item);
  };

  const showTotal = (total) => <TotalLabel total={total} />;

  return (
    <>
      <Modal
        width={1200}
        title={intl.formatMessage({ id: 'content-image-preview-card-title' })}
        centered
        visible={previewModalOpen}
        onCancel={handlePreviewModalClose}
        footer={false}
        closeIcon={<RiCloseFill className="remix-icon text-color-black-100" size={24} />}
      >
        {isEdit ? (
          <EditCollectionImageCard
            imageInitialValues={imageInitialValues}
            data={previewImage}
            isLoading={isAttaching}
            collectionDetails={collectionDetails}
            needToAttachImageWithUpdate={needToAttachImageWithUpdate}
            onModalClose={handlePreviewModalClose}
          />
        ) : (
          <ImageModalCardBodyView
            isLoading={isCategoriesLoading || isImagesFetching || isSyntheticImagesLoading}
            data={previewImage}
          />
        )}
      </Modal>

      <Row
        gutter={[ 32, 32 ]}
        justify="space-between"
        className="hp-mb-32"
        align="middle"
      >
        <BreadCrumbs
          breadCrumbParent={
            <Link to={urlPageCollections()}>
              <IntlMessages id="collections-breadcrumbs" />
            </Link>
          }
          breadCrumbParent2={(
            <Link to={urlPageCollectionDetails({ collectionId })}>
              {collectionId}
            </Link>
          )}
          breadCrumbActive={<IntlMessages id='ui-general-edit' />}
        />

        <Col md={12} span={24}>
          <Row
            gutter={[ 8, 8 ]}
            justify="end"
            align="middle"
          >
            {allowUnpublish && (
              <Col>
                <Space>
                  <ActionButton
                    loading={isUnpublishCollectionLoading}
                    title={intl.formatMessage({ id: 'ui-general-unpublish' })}
                    icon={<MdOutlineUnpublished className="hp-mr-8" size={18} />}
                    onClick={handleUnpublish}
                  />
                </Space>
              </Col>
            )}
            <Col>
              <Space>
                <Link to={urlPageCollections()}>
                  <Button
                    type="primary"
                    className="hp-mr-sm-8"
                    ghost="true"
                    icon={<RiArrowLeftLine className="hp-mr-8" size={18} />}
                  >
                    <IntlMessages id='ui-general-back-to-list' />
                  </Button>
                </Link>
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row gutter={[ 32, 32 ]} className="hp-mb-32">
        <Col span={24}>
          <Card className="hp-border-color-black-40 hp-card-6">
            <FormEditCollection
              initialErrors={modifiedErrors}
              isSubmitting={isUpdatingCollection}
              isLoading={isFetchingCollection || isCategoriesLoading || isAttaching}
              initialValues={initialValues}
              collectionConfig={collectionConfig}
              validationSchema={validation}
              onSubmit={handleUpdateCollection}
              templateOptions={[ {
                label: collectionDetails?.template?.data?.title,
                value: collectionDetails?.template?.data?.id,
              } ]}
              categoriesOptions={categoriesOptions}
              imageTypeOptions={imageTypeOptions}
              templateFields={collectionDetails?.template?.data?.fields?.data || []}
              isEnabledTemplateStatus={Boolean(collectionDetails?.template?.data?.is_active)}
              hasReleasedImages={collectionDetails?.images?.data.length > 0}
              releasedImages={collectionDetails?.images?.data}
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={[ 32, 32 ]} ref={imagesRef}>
        <Sidebar
          visible={isFilterOpen}
          toggleSidebar={toggleFilterSidebar}
          width={600}
        >
          <FormImagesFilter
            isSubmitting={isImagesFetching}
            initialValues={initFilterValues}
            excludedFields={[ 'status', 'released_from', 'released_to' ]}
            onCancel={() => {
              resetFilter({
                searchQueryParams,
                setSearchParams,
                setInitFilterValues,
              });
            }}
            onSubmit={(values) => {
              applyFilter({
                values,
                searchQueryParams,
                searchFilterFields,
                setSearchParams,
                toggleFilterSidebar,
              });
            }}
          />
        </Sidebar>

        <Col xs={24} md={12}>
          <Spinner spinning={isImagesFetching || isSyntheticImagesLoading || isAttaching}>
            <Card
              width="100%"
              title={(
                <div className="hp-d-flex hp-d-flex-between hp-align-items-center">
                  <IntlMessages
                    id={collectionDetails.image_type === imageStatuses.release_on
                      ? 'ui-general-ready-release-on'
                      : 'ui-general-storage-images'}
                  />
                  <ActionButton
                    title=""
                    size='small'
                    isActive={!isEmpty(initFilterValues)}
                    className='hp-mr-sm-8'
                    icon={<Filter size={18} />}
                    onClick={toggleFilterSidebar}
                  />
                </div>
              )}
            >
              {images?.length ? (
                <Row gutter={[ 16, 32 ]}>
                  {images?.map((item) => {
                    return (
                      <Col key={item.id} className='gutter-row' xs={24} sm={12} xxl={8}>
                        <ContentCard
                          data={item}
                          onClick={() => handleClickOnNonAttachedImage(item)}
                          actions={actions(item)}
                        />
                      </Col>
                    );
                  })}
                  <Col span={24}>
                    {dataPagination.total && (
                      <Pagination
                        total={dataPagination.total}
                        showTotal={showTotal}
                        pageSize={pagination.limit}
                        current={pagination.page}
                        pageSizeOptions={[ '12', '24', '48', '96' ]}
                        showSizeChanger
                        onChange={(page, size) => {
                          handleChangeTableParams({
                            current: page,
                            pageSize: size,
                          }, {}, {});
                        }}
                      />
                    )}
                  </Col>
                </Row>
              ) : <EmptyDataPlaceholder placeholder={<IntlMessages id="ui-general-no-images-found" />} />}
            </Card>
          </Spinner>
        </Col>

        <Col xs={24} md={12}>
          <Spinner spinning={isFetchingCollection || isAttaching}>
            <Card
              width="100%"
              title={
                <div className="hp-d-flex hp-d-flex-between hp-align-items-center">
                  <IntlMessages id="sidebar-content" />
                  <TotalLabel total={attachedImagesCount} />
                </div>
              }
            >
              <Row gutter={[ 16, 32 ]}>
                {attachedImagesCount ?
                  collectionDetails?.images?.data?.map((item) => {
                    return (
                      <Col key={item.id} className='gutter-row' xs={24} sm={12} xxl={8}>
                        <ContentCard
                          data={item}
                          onClick={() => handleClickOnAttachedImage(item)}
                          actions={actions(item, true)}
                        />
                      </Col>
                    );
                  }) : <EmptyDataPlaceholder placeholder={<IntlMessages id="achievements-no-attached-images" />} />}
              </Row>
            </Card>
          </Spinner>
        </Col>
      </Row>
    </>
  );
};

export default PageEditCollection;
